

import Helper from "../polyfills/helper";

const Tab = (function(){
  let tabsElement;

  function init() {
    const tabTriggers = document.querySelectorAll('[data-tab-trigger]')

    Array.from(tabTriggers).forEach(tabTrigger => {
      tabTrigger.removeEventListener('click', onTabEvent)
      tabTrigger.addEventListener('click', onTabEvent)

      if (tabTrigger.type == 'checkbox'){
        onTab(tabTrigger.dataset.tabTrigger, tabTrigger)
      }
    })
  }

  function onTabEvent(event) {
    const tabTriggers = event.currentTarget.dataset.tabTrigger;
    onTab(tabTriggers, event.currentTarget)
  }

  function onTab(tabTriggers, target) {
    if (tabTriggers.includes(',')) {
      const tabs = tabTriggers.split(',')
      const activeTab = target.checked ? tabs[0] : tabs[1];
      showTab(activeTab)
    } else {
      showTab(tabTriggers)
    }
  }

  function showTab(tabId) {
    resetTabs()
    const tab = document.querySelector('[data-tab="' + tabId + '"]')
    if (tab) {
      tab.classList.remove('hidden')
    }

    const tabTrigger = document.querySelector('[data-tab-trigger="' + tabId + '"]')
    if (tabTrigger) {
      tabTrigger.classList.add('is--active')
    }
  }

  function resetTabs() {
    Array.from(document.querySelectorAll('[data-tab]')).forEach(function(tab) {
      if (!tab.classList.contains('hidden')) {
        tab.classList.add('hidden')
      }
    });

    Array.from(document.querySelectorAll('[data-tab-trigger]')).forEach(function(tabElement) {
      tabElement.classList.remove('is--active')
    });
  }

  return {
    init: init,
    showTab: showTab,
    resetTabs: resetTabs
  }
})();

export default Tab;
