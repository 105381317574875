const Password = (function(){
  function init() {
    hoopUpPasswordInputs();
  }

  function hoopUpPasswordInputs() {
    const hiddenVisiblePasswordToggelers = document.querySelectorAll('[data-toggle-password]')
    Array.from(hiddenVisiblePasswordToggelers).forEach(element => {
      element.removeEventListener('click', onToggle)
      element.addEventListener('click', onToggle)
    })
  }

  function onToggle(event) {
    const hiddenVisiblePasswordToggler = event.currentTarget;
    const parent = hiddenVisiblePasswordToggler.parentElement.parentElement;
    const target = parent.querySelector('input')

    if (target.type == 'password') {
      target.type = 'text';
      parent.classList.add('password_visible');
    } else {
      parent.classList.remove('password_visible');
      target.type = 'password'
    }
  }


  return {
    init: init,
  }
})();

export default Password;
