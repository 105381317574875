import {Toast} from 'bootstrap'
import Helper from "../polyfills/helper";

const Toasts = (function(){

  function init() {
    document.removeEventListener('ajax::refresh', init)
    document.addEventListener('ajax::refresh', init)

    Helper.handleCustomEvent('toast_created', initNewToast)

    const toastElementList = Array.from(document.querySelectorAll('.toast'))
    toastElementList.forEach((toastEl) => {
      initToast(toastEl)
    })
  }

  function initNewToast(data){
    initToast(data.detail.payload)
  }

  function initToast(toastEl){
    const toast = new Toast(toastEl, {
      animation: true,
      autohide: toastEl.dataset.autoHide == '1',
      delay: 5000
    })

    if (Helper.hasDataAttribute(toastEl, 'showInitial')) {
      toast.show();
    }
  }

  return {
    init: init
  }
})();

export default Toasts;