import Helper from "../polyfills/helper";

const Filters = (function(){

  function init() {
    const filterElements = document.querySelectorAll('[data-filters]');
    const filters = Array.from(filterElements);

    filters.forEach(filter => {
      handleFilters(filter)
    });
  }

  function handleTableFilters() {

  }

  function handleFilters(filterElement) {
    const filterDialogElement = filterElement.querySelector('[data-filter-dialog]')
    if (Helper.isNull(filterDialogElement))  { return; }

    let hasActiveFilters = false;

    const filters = filterElement.querySelectorAll('[data-filter]')
    const filtersTagsElement = filterElement.querySelector('[data-filter-tags]')

    filtersTagsElement.innerHTML = ''
    filters.forEach(element => {
      if ((element.type == 'checkbox' || element.type == 'radio') && element.checked) {
        addFilterTag(element.parentElement.querySelector('label').innerText, element, filtersTagsElement)
        hasActiveFilters = true
      }
    })

    // if (hasActiveFilters) {
    //   const dialog = document.getElementById('filterDialog')
    //   const modal = new Collapse(dialog)
    //   modal.show()
    // } else {
    // }
  }

  function addFilterTag(name, element, filtersTagsElement) {
    const tag = document.createElement('div')
    tag.classList.add('tag')
    tag.dataset.target = element.id
    tag.innerHTML = '<span>' + name + '<i class="fa fa-times"></i></span>'
    filtersTagsElement.appendChild(tag);

    tag.removeEventListener('click', handleTagClicked)
    tag.addEventListener('click', handleTagClicked)
  }

  function handleTagClicked(event) {
    document.getElementById(event.currentTarget.dataset.target).click()
    handleTableFilters()
  }

  return {
    init: init
  }
})();

export default Filters;
