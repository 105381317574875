import Rails from "@rails/ujs";
import Helper from "../polyfills/helper";

const Modal = (function(){

  function init(){
    var modalElements = Array.from(document.querySelectorAll('.modal'))
    modalElements.forEach(modalElement => {
      modalElement.removeEventListener('show.bs.modal', onModalShowing)
      modalElement.addEventListener('show.bs.modal', onModalShowing)

      modalElement.removeEventListener('hidden.bs.modal', onModalHidden)
      modalElement.addEventListener('hidden.bs.modal', onModalHidden)
    });
  }

  function onModalShowing(event){
    const modal = event.currentTarget;

    Helper.dispatchEvent('modal::showing', {
      modal: modal
    });
  }

  function onModalHidden(event){
    const modal = event.currentTarget;
    Helper.dispatchEvent('modal::hiding', {
      modal: modal
    });
  }

  return {
    init: init
  }
})();

export default Modal
