import CookieStorage from "../polyfills/cookie";

const LanguageSwitch = (function(){
  function init(){
    const switchElements = document.querySelectorAll('[data-languages-switch]')
    Array.from(switchElements).forEach(switchElement => {
      if (switchElement) {
        switchElement.removeEventListener('change', languageChanged)
        switchElement.addEventListener('change', languageChanged)
      }
    });

    if (!getCookie("language")) {
      const languagePopup = document.getElementById('language_select_popup')
      if (languagePopup) {
        Expander.show(languagePopup)

        const languageSubmitElement = document.getElementById('languages_initial_submit')
        if (languageSubmitElement) {
          languageSubmitElement.removeEventListener('click', initialLanguageSubmit)
          languageSubmitElement.addEventListener('click', initialLanguageSubmit)
        }
      }
    }
  }

  function languageChanged(event) {
    const selectElement = event.currentTarget;
    const option = selectElement.options[selectElement.selectedIndex];
    CookieStorage.setCookie("language", encodeURIComponent(option.value) + "; path=/");
    window.location.reload(true)
  }

  function initialLanguageSubmit(event) {
    const selectElement = document.getElementById('languages_initial_select')
    const option = selectElement.options[selectElement.selectedIndex];
    CookieStorage.setCookie("language", encodeURIComponent(option.value) + "; path=/");
    window.location.reload(true)
  }

  function getCookie(name) {
    return CookieStorage.getCookie(name)
  }

  return {
    init: init
  }
})()

export default LanguageSwitch;
