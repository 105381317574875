// import {Toast} from 'bootstrap'
import Helper from "../polyfills/helper";
import Cloner from "../elements/clone";

const Notification = (function () {

  function createNotice(target, templateKey, title, text = '', data = null) {
    const noticeContainer = document.querySelector(target)
    if (Helper.isEmpty(noticeContainer)) { return; }

    const template = noticeContainer.querySelector(templateKey)

    const sanitizedData = {
      title: title,
      text: text,
    }

    if (data) {
      sanitizedData.payload_text = data.text
      sanitizedData.payload_url = data.url
    }
    const element = Cloner.createElementFromTemplate(sanitizedData, template)
    noticeContainer.appendChild(element)
    window.setTimeout(_ => {
      const firstChild = noticeContainer.querySelector('div')
      if (firstChild) {
        noticeContainer.removeChild(firstChild)
      }
    }, 8000);
  }

  function createToast(title, text = '', data = null){
    createToastElement(title, text, data)
  }

  function createAlert(title, text = '', data = null){
    const toast = createToastElement(title, text, data)
    toast.classList.add('toast-danger')
  }

  function createToastElement(title, text = '', data = null){
    const toastContainer = document.querySelector('[data-toast-container]')
    const toast = Helper.createElement('div', 'toast fade show', null, toastContainer, {'showInitial': '1'})

    const toastHeader = Helper.createElement('div', 'toast-header', null, toast)
    Helper.createElement('div', 'me-auto', title, toastHeader)

    const date = new Date;
    const minutes = date.getMinutes();
    const hour = date.getHours();
    Helper.createElement('div', 'text-muted', "" + hour + " : " + minutes, toastHeader)

    Helper.createElement('button', 'btn-close', null, toastHeader, {'bsDismiss': 'toast'})

    const toastBody = Helper.createElement('div', 'toast-body', text, toast)

    if (data){
      toastBody.classList.add('has--line-items')
      if (data.type == 'url'){
        const lineItem = Helper.createElement('div', 'line-item', null, toastBody)
        Helper.createElement('a', '', data.text, lineItem, null, {'href': data.url})
      } else {
        Helper.createElement('div', 'line-item', data.text, toastBody)
      }
    }

    Helper.dispatchEvent('toast_created', toast )

    window.getComputedStyle(toast).transform //force layout
    window.setTimeout(_ => {
      toastContainer.removeChild(toast)
    }, 8000);

    return toast;
  }

  return {
    createToast: createToast,
    createAlert: createAlert,
    createNotice: createNotice
  }

})();

export default Notification
