// redirect -> return head :found, x_ajax_redirect_url: client_group_path(@group)
// errors -> return render json: {
//           errors: @post.errors,
//           status: 400
//           html: <<optional html to show in target>>
//         }, status: 400

// normal view -> render partial

import Helper from "../polyfills/helper";
import UrlHelper from "../polyfills/url_helper";
import Api from "./api";

const RemoteTable = (function(){

  let elementId = null;

  function init(asyncContainer) {
    handleAsyncContainer(asyncContainer);
  }

  function element(){
    return document.getElementById(elementId)
  }

  function selectedSortOrder() {
    return element().getAttribute('order');
  }

  function setSelectedSortOrder(value){
    element().setAttribute('order', value);
  }

  function selectedSortColumn() {
    return element().getAttribute('col');
  }

  function setSelectedSortColumn(value){
    element().setAttribute('col', value);
  }

  function handleAsyncContainer(element) {
    elementId = element.id

    if (Helper.isEmpty(elementId)) { alert('Target heeft een id nodig')}

    setSelectedSortOrder('desc');
    setSelectedSortColumn('');

    initializeSortElements();
  }

  function initializeSortElements() {
    const sortColumnElement = element().querySelector('#sort_column');
    if (!sortColumnElement) { return; }

    setSelectedSortColumn(element().querySelector('#sort_column').value);
    setSelectedSortOrder(element().querySelector('#sort_order').value);

    setSortElementHeaders();
    attachSortElementHandlers();
    attachPagingHandlers();
    attachSubmitHandlers();
  }

  function setSortElementHeaders() {
    Array.from(element().querySelectorAll('.sort-column')).forEach(th => {
      if (th.dataset.column === selectedSortColumn()) {
        th.classList.add('sort-active');
        th.classList.add(selectedSortOrder());
        setSelectedSortColumn(th.dataset.column);
      } else {
        th.classList.remove('sort-active')
      }
    });
  }

  function attachSortElementHandlers() {
    Array.from(element().querySelectorAll('.sort-column')).forEach(th => {
      th.removeEventListener('click', onSortElementClicked);
      th.addEventListener('click', onSortElementClicked);
    })
  }

  function onSortElementClicked(event) {
    const new_sort_column = event.currentTarget.dataset.column;

    if (selectedSortColumn() === new_sort_column ) {
      setSelectedSortOrder(selectedSortOrder() === 'desc' ? 'asc' : 'desc')
    } else {
      setSelectedSortOrder('desc');
    }

    setSelectedSortColumn(new_sort_column);

    const formElement = element()
    formElement.querySelector('#sort_column').value = selectedSortColumn();
    formElement.querySelector('#sort_order').value = selectedSortOrder();
    Api.submit(formElement)
  }

  function attachPagingHandlers() {
    const paginationElement = element().querySelector('.pagination');
    if (!paginationElement) { return; }

    Array.from(paginationElement.querySelectorAll('a')).forEach(a => {
      a.removeEventListener('click', onPagingHandlerClicked);
      a.addEventListener('click', onPagingHandlerClicked);
    });
  }

  function onPagingHandlerClicked(event){
    event.stopPropagation();
    event.preventDefault();
    const page = UrlHelper.getParameterByName('page', event.currentTarget.href);
    element().querySelector('#page').value = page;

    Api.submit(element())
    return false;
  }

  function attachSubmitHandlers() {
    const submitElements = element().querySelectorAll('[data-remote-submit]');
    if (!submitElements) { return; }

    Array.from(submitElements).forEach(submitElement => {
      submitElement.removeEventListener('click', submitForm)
      submitElement.addEventListener('click', submitForm);
    })
  }

  function submitForm(event) {
    Api.submit(event.currentTarget.form)
  }

  return {
    init: init
  }
})();

const RemoteTables = (function(){

  function init() {
    console.log('init remoteforms')
    Helper.handleCustomEvent('ajax::refresh', init)

    const asyncContainers = document.querySelectorAll('[data-remote-table]');
    if (asyncContainers == null || asyncContainers.length === 0) { return; }

    Array.from(asyncContainers).forEach((asyncContainer) => {
      RemoteTable.init(asyncContainer);
    })
  }

  return {
    init: init
  }
})();

export {
  RemoteTables,
  RemoteTable
};
