import Helper from "../polyfills/helper";

const Dropdown = (function(){

  function init() {
    Array.from(document.querySelectorAll('.dropdown')).forEach(element => {
      element.removeEventListener('hide.bs.dropdown', handleDropdownItemClick)
      element.addEventListener('hide.bs.dropdown', handleDropdownItemClick)
    })
  }

  function handleDropdownItemClick(event){
    if (Helper.isNull(event.clickEvent)) { return true; }
    if (Helper.hasDataAttribute(event.currentTarget, 'bsKeepOpen')) {
      if (Helper.isNull(event.clickEvent.target.closest('.dropdown'))){
        return true; //click outside of dropdown
      }

      event.preventDefault();
      event.stopPropagation();
      return false;
    }
    return true;
  }

  return {
    init: init
  }

})();

export default Dropdown;
