import CookieStorage from "../polyfills/cookie";

const ThemeSwitch = (function(){
  function init(){
    const switchElements = document.querySelectorAll('[data-theme-switch]')
    Array.from(switchElements).forEach(switchElement => {
      if (switchElement) {
        switchElement.removeEventListener('change', themeChanged)
        switchElement.addEventListener('change', themeChanged)
      }
    });
  }

  function themeChanged(event) {
    const selectElement = event.currentTarget;
    const option = selectElement.options[selectElement.selectedIndex];
    CookieStorage.setCookie("theme", encodeURIComponent(option.value) + "; path=/");
    window.location.reload(true)
  }

  return {
    init: init
  }
})()

export default ThemeSwitch;
