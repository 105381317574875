const HelpIcon = (function (){

  function init(){
    const helpIcons = Array.from(document.querySelectorAll('[data-help-icon]'));
    helpIcons.forEach(element => {
      element.removeEventListener('click', onHelpIconClicked)
      element.addEventListener('click', onHelpIconClicked)
    })

    document.addEventListener('click', dismissHelpIcons)
    document.addEventListener('click', dismissHelpIcons)
  }

  function onHelpIconClicked(event){
    const element = event.currentTarget;
    const display = element.querySelector('[data-help-display]');
    if (display) {
      dismissHelpIcons();
    } else {
      const displayElement = document.createElement('div')
      displayElement.dataset.helpDisplay = true;
      displayElement.className = 'input-help-icon-display';
      displayElement.innerText = element.dataset.helpIcon;
      element.appendChild(displayElement);
    }
    event.preventDefault();
    event.stopPropagation();
  }

  function dismissHelpIcons(){
    const helpIcons = Array.from(document.querySelectorAll('[data-help-icon]'));
    helpIcons.forEach(element => {
      const display = element.querySelector('[data-help-display]');
      if (display){
        display.remove();
      }
    })
  }

  return {
    init: init
  }
})();

export default HelpIcon;
