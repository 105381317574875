// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import '../polyfills/index'

import '../stylesheets/application.scss'

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import '../ajax/index'
import '../elements/index'
import '../models/index'
import '../table/index'
import '../form/index'
import '../settings/index'
import '../notifications/index'
import '../utils/index'

import 'bootstrap'

// import '../sse/server_notifications'
// import '../tensorflow/app'

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
