import Api from "../ajax/api";
import Helper from "../polyfills/helper";

const Wwft = (function(){

  function init(){
    Helper.handleCustomEvent('ajax::refresh', init)

    const wwftForm = document.getElementById('wwft_form');
    if (Helper.isNull(wwftForm)){ return; }

    const elements = wwftForm.elements

    Array.from(elements).forEach(element => {
      if (element.id != 'wwft_kind') {
        element.removeEventListener('change', handleChange)
        element.addEventListener('change', handleChange)
      }
    })

    window.setTimeout(function() {
      handleWwftKinds();
    }, 100);
  }

  function handleWwftKinds(){
    const wwftKindNumberElement = document.getElementById('wwft_kind_number')
    const wwftKindSelectElement = document.getElementById('wwft_kind')
    const wwftMonitoringsProfile = document.getElementById('wwft_monitorings_profile')

    if (wwftKindNumberElement && wwftKindSelectElement){
      const options = Array.from(wwftKindSelectElement.querySelectorAll('option'))

      options.forEach(element => {
        if (parseInt(element.dataset.kind) < wwftKindNumberElement.value){
          element.disabled = true;
          element.selected = false;
          element.pointerEvents = 'none';
        } else {
          element.disabled = false;
          element.pointerEvents = 'auto';
        }
      })
    }

    if (wwftKindNumberElement && wwftMonitoringsProfile){
      const options = Array.from(wwftMonitoringsProfile.querySelectorAll('option'))
      options.forEach(element => {
        console.log('-----')
        console.log(500 - wwftKindNumberElement.value)
        console.log(parseInt(element.dataset.kind))
        console.log(parseInt(element.dataset.kind) < (500 - wwftKindNumberElement.value))

        if (parseInt(element.dataset.kind) <= (500 - wwftKindNumberElement.value) ){
          element.disabled = false;
          element.pointerEvents = 'auto';
        } else {
          element.disabled = true;
          element.selected = false;
          element.pointerEvents = 'none';
        }
      })
    }
  }

  function handleChange(event){
    const element = event.currentTarget;
    const form = element.form;

    window.setTimeout(function() {
      callEndpoint(form);
    }, 1000);
  }

  function callEndpoint(form) {
    const elements = form.elements

    let wwft = {}
    Array.from(elements).forEach(element => {
      const name = element.name.replace('wwft[', '').replace(']', '');

      if (element.type == 'radio' || element.type == 'checkbox') {
        if (element.checked) {
          wwft[name] = element.value
        }
      } else if(element.type != 'hidden') {
        wwft[name] = element.value;
      }
    });

    const request = new Request('/admin/wwfts/risk_profile');
    Api.callApi(request, 'POST', wwft)
      .then((result) => {
        debugger
        document.getElementById('wwft_kind').value = result.risk
        window.setTimeout(function() {
          document.getElementById('wwft_kind').dispatchEvent(new Event('change'));
        }, 1);
      })
      .catch(ex => {
      });
  }

  return {
    init: init
  }
})();

export default Wwft;
