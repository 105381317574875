import Modal from "./modal";
import DropDown from "./dropdown";
import LazyLoader from "./lazy-loaders";
import Tab from "./tabs";
import HelpIcon from "./help-icon";

document.addEventListener("DOMContentLoaded", function() {
  DropDown.init();
  Modal.init();
  LazyLoader.init();
  Tab.init();
  HelpIcon.init();
});

