import consumer from './consumer'
import Notification from "../notifications/notification";

const notificationChannel = consumer.subscriptions.create('NotificationChannel', {

  initialized() {
    this.update = this.update.bind(this)
  },

  connected() {
    this.install()
    this.update()
  },

  rejected() {
    this.uninstall()
  },

  disconnected() {
    this.uninstall()
  },

  received(data) {
    if (data.type = 'notice') {
      Notification.createNotice(data.selector, data.template, data.title, data.body, data.payload)
    }
  },

  appear() {
    // Calls `#appear(data)` on the server.
    this.perform("appear", { appearing_on: 'hello' })
  },

  away() {
    // Calls `#away` on the server.
    this.perform("away")
  },

  update() {
    // has focus is niet echt betrouwbaar als je bijvoorbeeld meerdere schermen open hebt naast elkaar
    // console.log(document.hasFocus());
    (document.visibilityState == 'visible') ? this.appear() : this.away()
  },

  install() {
    window.addEventListener("focus", this.update)
    window.addEventListener("blur", this.update)
    document.addEventListener("DOMContentLoaded", this.update)
    document.addEventListener("visibilitychange", this.update)
  },

  uninstall() {
    window.removeEventListener("focus", this.update)
    window.removeEventListener("blur", this.update)
    document.removeEventListener("DOMContentLoaded", this.update)
    document.removeEventListener("visibilitychange", this.update)
  },
})

// window.setTimeout(() => {
//   console.log('-> sending data')
//   chatChannel.send({sent_by: "XXX", body: "After timeout"})
// }, 5000);

export default notificationChannel;
