const AnimationHandler = (function(){
  function init() {
    document.removeEventListener('ajax::refresh', init)
    document.addEventListener('ajax::refresh', init)

    const animated = document.querySelectorAll('.animation');

    document.removeEventListener('animationend', onAnimationEnded)
    document.addEventListener('animationend', onAnimationEnded)
  }

  function onAnimationEnded(event){
    const element = event.target;
    const className = element.className.replace('animation', '').replace(/\s/g, '')

    element.classList.add(className + '--done')
  }

  return {
    init: init
  }
})();

export default AnimationHandler;
