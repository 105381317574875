const FileService = (function(){
  function showPreview(event) {
    const input = event.currentTarget;

    if (input.files && input.files[0]) {
      const preview = input.dataset.target ? document.querySelector(input.dataset.target) : input.parentElement

      const allowedArray = input.dataset.onlyImages == '1' || input.dataset.onlyImages == 'true' ? ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'] : null;

      const fileType = input.files[0].type;
      const isAllowed = allowedArray === null || allowedArray.indexOf(fileType) > -1;
      if (!isAllowed){
        preview.classList.add('bg-danger');
        input.value = '';
        return
      }

      if (isImage(fileType)) {
        const icon = preview.querySelector("svg");
        if (icon) {
          icon.classList.add('hidden')
        }

        const reader = new FileReader();
        reader.onload = function (e) {
          if (preview.tagName.toLowerCase() == 'img'){
            preview.setAttribute('src', e.target.result)
          } else {
            preview.style.backgroundImage = 'url(' + e.target.result + ')';
          }
        };
        reader.readAsDataURL(input.files[0]);
      } else {
        preview.style.backgroundImage = ''
        const icon = preview.querySelector("i");
        icon.classList.remove('hidden')
        icon.classList.remove('fa-plus')
        icon.classList.add('fa-file')
        //TODO  word powerpoint pdf excel csv
      }
    }
  }

  function isImage(fileType){
    if (fileType === "image/jpeg") {return true;}
    if (fileType === "image/jpg") {return true;}
    if (fileType === "image/png") {return true;}
    if (fileType === "image/gif") {return true;}

    return false;
  }

  return {
    showPreview: showPreview,
    isImage: isImage
  }
})();

export default FileService;